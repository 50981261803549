import React, { Component } from "react";
import { Row, Col } from "react-grid-system";
import { navigate } from "gatsby";
import Markdown from "react-remarkable-with-plugins";

import {
  Container,
  Input,
  Select,
  Submit,
  Label,
  SubLabel,
  Textarea,
  Waiver
} from "components/Cohort/FormStyles";

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value
});

class Application extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      referral: "",
      clicked: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    const submission = {
      name: this.state.name,
      email: this.state.email,
      referral: this.state.referral
    };
    var data = new FormData();
    for (var key in submission) {
      data.append(key, submission[key]);
    }

    fetch(
      "https://script.google.com/macros/s/AKfycbxkcYwXHVJLRepxWYbaHfxm5iDThe1fBJOqhMM09zFBxDMQVj10/exec",
      { method: "POST", body: data }
    )
      .then(response => {
        console.log("Interest form submitted.");
      })
      .catch(error => {
        console.error("Error!", error.message);
        alert("There was an error submitting your form. Please try again.");
      });
    event.preventDefault();
  }

  render() {
    return (
      <Container>
        <form onSubmit={this.handleSubmit}>
          <Input
            type="text"
            name="name"
            placeholder="Full Name"
            value={this.state.name}
            onChange={event =>
              this.setState(updateByPropertyName("name", event.target.value))
            }
          />
          <br/><br/>
          <Input
            type="text"
            name="email"
            placeholder="Email"
            value={this.state.email}
            onChange={event =>
              this.setState(updateByPropertyName("email", event.target.value))
            }
          />{" "}
          <Label>How did you hear about us?</Label>
          <Input
            type="text"
            name="email"
            placeholder="Friend, LinkedIn, etc."
            value={this.state.referral}
            onChange={event =>
              this.setState(
                updateByPropertyName("referral", event.target.value)
              )
            }
          />{" "}
          <br />
          <br />
          <Submit
            type="submit"
            onClick={() => this.setState({ clicked: true })}
          >
            {this.state.clicked ? "Thank you!" : <span>Submit</span>}
          </Submit>
        </form>
      </Container>
    );
  }
}

export default Application;
