import styled, { keyframes } from "styled-components";

export const Header = styled.header`
  width: 100%;
  height: 600px;
  background-image: url("/img/cohort/wave.svg");
  background-repeat: no-repeat;
  background-position: left bottom;
  main {
    display: flex;
    align-items: center;
  }

  div {
    flex: 1;
    align-items: center;
  }

  h1 {
    padding-left: 32px;
    padding-bottom: 16px;
    color: #333;
    font-size: 64px;
  }

  h2 {
    color: #474747;
    padding: 0px 32px;

    span {
      font-weight: bold;
    }
  }

  h3 {
    color: #474747;
    padding: 0px 32px;
    font-size: 24px;
  }

  .dates {
    color: #474747;
    padding-top: 16px;
    padding-left: 32px;
    font-size: 24px;
    font-weight: bold;
    padding-bottom: 16px;
  }

  a {
    margin: 32px;
  }

  @media (max-width: 1200px) {
    height: 750px;
    main {
      display: block;
    }
    h1 {
      font-size: 48px;
      padding: 16px;
    }
    h2 {
      font-size: 24px;
      padding: 0px 16px;
    }

    h3 {
      padding: 0px 16px;
    }
    a {
      margin: 16px;
    }
    .dates {
      padding-left: 16px;
    }
  }

  .pitch-video {
    text-align: center;
    font-size: 24px;
    margin: 24px 0px;
    font-style: italic;
  }

  @media (max-width: 1200px) {
    .pitch-video {
      margin-left: 16px;
      margin-right: 16px;
      font-size: 16px;
      margin-top: 36px;
      margin-bottom: 8px;
    }
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const QuickFacts = styled.div`
  padding: 16px;
  width: 100%;
  margin: 36px 24px;

  section {
    display: flex;
  }
  div {
    flex: 1;
  }

  h2 {
    text-transform: uppercase;
    color: #474747;
    font-size: 14px;
    font-weight: normal;
    margin: 4px 0px;
    letter-spacing: 1px;
  }

  h3 {
    color: #333;
    border-bottom: 2px solid #438cee;
    display: inline-block;
    margin-bottom: 8px;
  }

  p {
    color: #474747;
    max-width: 70%;
    margin-bottom: 16px;
  }

  @media (max-width: 1200px) {
    section {
      display: block;
      max-width: 100%;
    }
  }
`;

export const Tagline = styled.div`
  text-align: center;
  font-size: 24px;
  padding: 24px;
  background: #f4f4f4;
`;

export const YouTubeContainer = styled.div`
  margin-top: 0;
  margin-bottom: 24px;
  margin-left: 12px;
  margin-right: 12px;
  padding: 12px;
  height: 380px;
  background: #f4f4f4;
  -moz-box-shadow: 0 0 16px #e4e4e4;
  -webkit-box-shadow: 0 0 16px #e4e4e4;
  box-shadow: 0 0 16px #e4e4e4;
  border-radius: 4px;
  iframe {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 768px) {
    margin: 4px;
    height: 275px;

    iframe {
      width: 90%;
      display: block;
      margin-right: auto;
      margin-left: auto;
    }
  }
`;

// background-image: url("/img/cohort/undraw_mic_drop_uuyg.svg");
// background-repeat: no-repeat;
// background-position: top right;

export const Community = styled.div`
  background-image: url("/img/cohort/wave2.svg");
  background-repeat: no-repeat;
  background-position: left bottom;
  padding: 16px;
  text-align: center;

  /* .fade {
    animation: ${fadeIn} 0.5s ease-in;
  } */

  button {
    font-size: 18px;
    background-color: #f3f3f3;
    border-radius: 6px;
    font-weight: bold;
    z-index: 1;
    background: #438cee;
    color: #fff;
    padding: 12px;
    border: none;
    &:hover {
      transition: all 300ms ease;
      cursor: pointer;
      opacity: .8;
    }
  }

  section {
    max-width: 95%;
    margin: auto;
    display: flex;
    justify-content: center;

    @media (max-width: 768px) {
      flex-direction: column;
    }
    @media (min-width: 768px) {
      flex-direction: row;
    }
  }
  #controls {
    margin: 0 auto;
    color: #333;
    text-align: center;
  }

  h3 {
    display: inline-block;
    color: #333;
    margin: 20px;
    @media (max-width: 400px) {
      margin: 5px;
      font-size: 20px;
    }
    cursor: pointer;
  }

  h2 {
    text-align: center;
    color: #333;
    padding: 16px 0px;
  }

  @media (max-width: 1200px) {
    section {
      display: block;
      max-width: 100%;
    }
  }
`;

export const CTAContainer = styled.div`
  height: 350px;
  display: flex;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #438cee;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 64px;
  font-weight: bold;
  color: #fafafa;
  flex-direction: column;

  p span {
    font-style: italic;
    display: inline;
  }
  p {
    color: #fafafa;
    margin-bottom: 20px;
  }
`;

export const FAQContainer = styled.div`
  margin: 0 auto;
  max-width: 90%;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 0 20px;

  h1 {
    margin: 36px 0px;
    color: #333;
  }

  h2 {
    color: #333;
    margin-bottom: 30px;
  }

  h3 {
    margin-bottom: 12px;
    color: #333;
    font-size: 18px;
  }

  p {
    color: #474747;
    line-height: 1.2;
  }

  div {
    padding: 0px 8px;
  }

  div > div {
    margin-bottom: 24px;
  }
`;

export const TeamContainer = styled.div`
  margin: 0 auto;
  max-width: 70%;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 0 20px;

  h1 {
    margin: 36px 0px;
    color: #333;
  }

  h4 {
    margin: 12px 0px;
    color: #333;
    font-size: 16px;
  }

  h3 {
    margin-bottom: 24px;
    color: #333;
    @media (max-width: 579px) {
      font-size: 36px;
    }
  }

  p {
    font-size: 18px;
    max-width: 40em;
    color: #474747;
    margin: auto;
  }

  div > div {
    margin-bottom: 25px;
  }
`;

export const ProfileCircle = styled.img`
  border-radius: 50%;
  width: 100px;
  height: 100px;
`;

export const Button = styled.a`
  padding: ${props => props.padding};
  font-size: 24px;
  background-color: #f3f3f3;
  border-radius: 6px;
  font-weight: bold;
  z-index: 1;
  background: #438cee;
  color: #fff;

  @media only screen and (max-width: 1200px) {
    font-size: 16px;
  }
  &:hover {
    background: transparent;
    color: #438cee;
    transition: all 300ms ease;
    cursor: pointer;
    span {
      background: linear-gradient(to right, #00c6ff, #0072ff);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      transition: all 300ms ease;
    }
  }
`;

export const ApplyButton = styled.a`
  padding: ${props => props.padding};
  font-size: 24px;
  background: #fafafa;
  border-radius: 6px;
  font-weight: bold;
  z-index: 1;
  color: #438cee;

  transition: all 300ms ease;

  @media only screen and (max-width: 1200px) {
    font-size: 16px;
  }
  &:hover {
    cursor: pointer;
    background: #e9e9e9;
    span {
      background: linear-gradient(to right, #00c6ff, #0072ff);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
`;

export const TestimonialDiv = styled.div`
  flex: 1;
  max-width: 25em;
  display: flex;
  flex-direction: column;
  margin: 16px;
  @media (max-width: 1200px) {
    margin: 16px auto;
  }
  text-align: center;
  background: #f4f4f4;
  padding: 16px;
  border-radius: 4px;
  -moz-box-shadow: 0 0 4px #e4e4e4;
  -webkit-box-shadow: 0 0 4px #e4e4e4;
  box-shadow: 0 0 4px #e4e4e4;

  .project p {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.2;
    text-align: center;
    margin: 8px 0px;
    color: #333;
    padding: 8px;
  }

  .project img {
    border-radius: 4px;
    max-width: 90%;
    margin: 8px 0px;
  }

  .profile {
    text-align: center;
    margin: 8px;
    margin-top: auto;
  }

  .profile p {
    color: #474747;
    margin: 8px 0px;
  }

  .profile img {
    border-radius: 50%;
  }

  small {
    margin: 4px auto;
    color: #474747;
  }
`;

export const Value = styled.div`
  max-width: 90%;
  margin: auto;
  display: flex;

  div {
    text-align: center;
    margin: 48px 0px;
    flex: 1;
    img {
      height: 150px;
    }
    h2 {
      font-size: 28px;
      color: #333;
      margin: 16px 0px;
    }
    p {
      color: #474747;
      max-width: 80%;
      margin: auto;
      line-height: 1.2;
    }
  }

  @media (max-width: 1200px) {
    max-width: 100%;
    display: block;
  }
`;

export const ApplyContainer = styled.div`
  text-align: center;
  max-width: 40em;
  margin: 0 auto;
  font-size: 16px;

  label {
    display: block;
  }

  form {
    text-align: left;
    margin: 20px;
  }

  input {
    padding: 5px 10px;
    box-sizing: border-box;
    margin-bottom: 15px;

    font-size: 16px;
    width: 100%;
  }

  select {
    padding: 5px 10px;
    margin-bottom: 15px;

    font-size: 16px;
    width: 100%;
  }

  label {
    margin-bottom: 5px;
  }
`;

export const Pricing = styled.section`
  max-width: 90%;
  margin: auto;

  h1 {
    color: #333;
    margin: 32px 0px;
    text-align: center;
  }

  h2 {
    color: #333;
    margin: 32px 0px;
    text-align: center;
  }

  div {
    max-width: 100%;
    background: #f4f4f4;
    padding: 16px;
    border-radius: 4px;
    -moz-box-shadow: 0 0 4px #e4e4e4;
    -webkit-box-shadow: 0 0 4px #e4e4e4;
    box-shadow: 0 0 4px #e4e4e4;
    max-width: 30em;
    margin: auto;
    text-align: center;
  }

  div h2 {
    color: #333;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  h4 {
    color: #474747;
    margin: 8px 0px;
    text-align: center;
  }

  p {
    font-size: 36px;
    font-weight: bold;
    text-align: center;
  }

  .price {
    margin: 8px 0px;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .price .cost {
    padding: 8px;
  }

  .price .compare {
    text-decoration: line-through;
    font-weight: normal;
    font-size: 24px;
  }

  .price .tag {
    border-radius: 4px;
    background: rgb(209, 240, 253);
    padding: 4px;
    font-size: 12px;
  }

  i {
    color: #474747;
  }

  ul {
    color: #474747;
    padding: 0;
  }

  ul li {
    list-style-type: none;
    margin: 8px auto;
  }

  .student {
    font-size: 16px;
    text-align: center;
    margin: 12px 0px;
    color: #333;
  }

  .student-registration {
    font-size: 16px;
    text-align: center;
    color: #474747;
  }
`;

export const RegistrationPricing = styled.div`
  margin: 24px;
`;

export const RegistrationHeader = styled.header`
  background-image: url("/img/cohort/wave.svg");
  background-repeat: no-repeat;
  background-position: left bottom;
  width: 100%;
  height: 600px;
  main {
    display: flex;
    align-items: center;
  }

  div {
    flex: 1;
    align-items: center;
  }

  h1 {
    padding: 32px;
    color: #333;
    font-size: 72px;
  }

  .referral-header {
    font-size: 36px;
  }

  h2 {
    color: #474747;
    padding: 0px 32px;

    span {
      font-weight: bold;
    }
  }

  h3 {
    color: #333;
    padding: 32px;

    span {
      font-weight: bold;
    }
  }

  h4 {
    color: #474747;
    padding: 0px 32px;

    span {
      font-weight: bold;
    }
  }

  @media (max-width: 1200px) {
    height: 900px;
    main {
      display: block;
    }
    h1 {
      font-size: 48px;
    }
    h2 {
      font-size: 24px;
    }
  }
`;

export const Message = styled.div`
  background: #f4f4f4;
  padding: 32px;

  h3 {
    color: #333;
  }

  h2 {
    color: #333;
    text-align: center;
  }

  h4 {
    color: #333;
    text-align: center;
    padding: 16px 0px;
  }

  section {
    margin: auto;
    max-width: 35em;
    padding: 12px;
  }

  section li {
    list-style-type: none;
    margin: 16px;
    font-size: 24px;
    color: #474747;
    display: flex;
    align-items: center;
  }

  @media (max-width: 1200px) {
    section li {
      font-size: 18px;
    }
  }

  section img {
    margin-right: 8px;
  }

  section p {
    padding: 0px;
    margin: 0;
    display: inline-block;
    box-sizing: border-box;
  }

  article {
    padding: 16px 0px;
    max-width: 80%;
    margin: auto;
    text-align: center;
    -moz-box-shadow: 0 0 8px #dbdbdb;
    -webkit-box-shadow: 0 0 8px #dbdbdb;
    box-shadow: 0 0 8px #dbdbdb;
    border-radius: 8px;
  }

  object {
    display: flex;
    justify-content: center;
    margin-top: 16px;
  }

  @media (max-width: 1200px) {
    article {
      max-width: 100%;
    }

    article span {
      font-size: 18px;
      display: block;
    }
  }

  article span {
    display: inline-block;
    margin-right: 36px;
    letter-spacing: -1px;
    font-size: 24px;
    font-weight: bold;
    color: #474747;
    transition: all 300ms ease;
    cursor: default;
  }

  article span:hover {
    opacity: 0.8;
  }
`;

export const TimelineProjectsContainerLanding = styled.div`
  width: 85%;
  margin: auto;
  text-align: center;
  position: relative;
  height: 75px;

  @media (max-width: 1200px) {
    display: block;
    position: static;
    height: auto;
  }
`;

export const Structure = styled.div`
  padding: 32px;

  section {
    display: flex;
  }

  h2 {
    color: #333;
    text-align: center;
  }

  h4 {
    color: #333;
    text-align: center;
    font-size: 36px;
    margin: 16px 0px;
  }

  article {
    padding: 16px 0px;
    max-width: 80%;
    margin: auto;
    text-align: center;
    -moz-box-shadow: 0 0 8px #dbdbdb;
    -webkit-box-shadow: 0 0 8px #dbdbdb;
    box-shadow: 0 0 8px #dbdbdb;
    border-radius: 8px;
  }

  object {
    display: flex;
    justify-content: center;
    margin-top: 16px;
  }

  @media (max-width: 1200px) {
    article {
      max-width: 100%;
    }

    article span {
      font-size: 18px;
      display: block;
    }
  }

  article span {
    display: inline-block;
    margin: 12px;
    letter-spacing: -1px;
    font-size: 24px;
    font-weight: bold;
    color: #474747;
    transition: all 300ms ease;
    cursor: default;
  }

  article span:hover {
    opacity: 0.8;
  }

  .mobile-timeline {
    font-size: 24px;
    text-align: center;
    display: none;

    h3 {
      color: #333;
      margin: 8px 0px;
    }

    p {
      color: #474747;
      margin-bottom: 16px;
    }
  }

  @media (max-width: 1200px) {
    .timeline {
      display: none;
    }
    .mobile-timeline {
      display: block;
    }
  }
`;

export const Title = styled.div`
  text-align: center;
  border-top: 1px solid #c9c9c9;
  padding-top: 32px;
  margin: 8px;

  p {
    text-transform: uppercase;
    font-size: 18px;
    margin-bottom: 16px;
    color: #474747;
    letter-spacing: 1px;
  }

  h2 {
    font-size: 36px;
    color: #333;
  }
`;
export const WebinarYouTubeContainer = styled.div`
  margin: auto;
  padding: 16px 0px;
  max-width: 75%;
  @media (max-width: 1200px) {
    max-width: 90%;
  }
  article {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`;
export const WebinarHeader = styled.header`
  margin: 16px 36px;
  h1 {
    color: #333;
  }

  h2 {
    color: #474747;
  }

  article {
    margin: 18px 0px;
  }

  h4 {
    margin: 16px 0px;
    text-align: center;
    color: #333;
    font-size: 36px;
  }

  h6 {
    margin-top: 0px;
    margin-bottom: 16px;
    text-align: center;
    color: #333;
    font-size: 18px;
  }

  h5 {
    margin: 16px 0px;
    text-align: center;
    color: #333;
    font-size: 36px;
    font-weight: bold;
    background: #f4fbff;
    display: inline-block;
    padding: 16px 8px;
  }
`;

export const SubmitBox = styled.div`
  padding: 16px;
  margin: 0 auto;
  max-width: 50em;
  -moz-box-shadow: 0 0 15px #e4e4e4;
  -webkit-box-shadow: 0 0 15px #e4e4e4;
  box-shadow: 0 0 15px #e4e4e4;
  border-radius: 4px;
  background: linear-gradient(to right, #56ccf2, #82b2f4);
  color: #333;
`;

export const SubmitInput = styled.input`
  font-size: 18px;
  display: block;
  border-radius: 4px;
  padding: 4px;
  color: white;
  border: 2px solid #e4e4e4;
  outline: none;
  background: transparent;
  width: 95%;
`;

export const SubmitButton = styled.a`
  cursor: pointer;
  border-radius: 4px;
  font-size: 24px;
  padding: 6px;
  background: transparent;
  border: 2px solid #e4e4e4;
  transition: all 300ms ease;
  outline: none;
  color: #fff;
  &:hover {
    opacity: 0.5;
  }
`;

export const Live = styled.div`
  color: #fff;
  background: #c54245;
  text-transform: uppercase;
  display: inline-block;
  letter-spacing: 1px;
  font-size: 14px;
  margin: 16px 0px;
  padding: 8px;
  border-radius: 8px;
`;

export const Premiered = styled.div`
  color: #333;
  text-transform: uppercase;
  display: inline-block;
  letter-spacing: 1px;
  font-size: 14px;
  margin: 12px 0px;
  padding: 4px;
  border-radius: 8px;
`;


