import React from "react";
import InterestForm from "components/Cohort/InterestForm";
import Helmet from "react-helmet";

import {
  RegistrationHeader,
  Pricing,
  RegistrationPricing
} from "components/Cohort/styles";

const apply = () => {
  return (
    <>
      <Helmet
        title="Cohort Interest | Enlight"
        meta={[
          {
            name: "description",
            content:
              "Enlight Cohorts are two week programs that teach you to code the apps and websites you've always wanted to make — even if you've had no prior experience."
          },
          {
            name: "og:title",
            content: "Cohort | Enlight"
          },
          {
            name: "og:description",
            content:
              "Enlight Cohorts are two week programs that teach you to code the apps and websites you've always wanted to make — even if you've had no prior experience."
          },
          { name: "og:url", content: "https://enlight.nyc/cohort" },
          {
            name: "og:image",
            content: "https://enlight.nyc/img/cohort/cohort-social.png"
          },
          {
            name: "twitter:title",
            content: "Cohort | Enlight"
          },
          {
            name: "twitter:description",
            content:
              "Enlight Cohorts are two week programs that teach you to code the apps and websites you've always wanted to make — even if you've had no prior experience."
          },
          {
            name: "twitter:image",
            content: "https://enlight.nyc/img/cohort/cohort-social.png"
          }
        ]}
      />

      <RegistrationHeader>
        <main>
          <div className="titles">
            <div>
              <h1>Stay updated.</h1>
              <h2>Fill out our short interest form to get notified when applications open.</h2>
              <br /> <br />
            </div>
          </div>

          <RegistrationPricing>
            <Pricing>
              <div>
                <h2>Enlight Learner</h2>
                <h4>Cohort #4: November</h4>
                <p className="price">
                  {" "}
                  <span className="compare"></span>{" "}
                  <span className="cost">TBA</span>{" "}
                  <span className="tag">one time</span>
                </p>
                <ul>
                  <li>
                    Build 4 <i>real</i> projects
                  </li>
                  <li>10 project-based webinars</li>
                  <li>Exclusive community & forum</li>
                  <li>Project recommendations</li>
                  <li>Certificate of completion</li>
                </ul>
                <br />
                {/* <i>Applications close July 21st. Limited spots — act soon.</i> */}
                <br /> <br />
              </div>
            </Pricing>
          </RegistrationPricing>
        </main>
      </RegistrationHeader>

      <InterestForm />
    </>
  );
};

export default apply;
