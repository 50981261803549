import styled from "styled-components";

export const Container = styled.div`
  padding: 24px;
  max-width: 60em;
  margin: 0 auto;
  color: #333;

  section {
      text-align: center;
  }

  section div {
      padding-right: 0;
  }

  section input {
    width: 100%;
  }

  section select {
      width: 100%;
  }
`;

export const Input = styled.input`
  font-size: 18px;
  padding: 8px;
  border: 2px solid #e4e4e4;
  border-radius: 5px;
  color: #474747;
  margin: 8px 0px;
  box-sizing: border-box;
  transition: all 300ms ease hover;

`;

export const Select = styled.select`
  font-size: 18px;
  padding: 8px;
  margin: 8px 0px;
  margin-right: 0px;
  box-sizing: border-box;
  margin-left: 0px; 
  color: #474747;
  border: 2px solid #e4e4e4;
  border-radius: 5px;
  transition: all 300ms ease hover;


`;

export const Submit = styled.button`
  padding: 12px;
  font-size: 24px;
  background-color: #f3f3f3;
  border-radius: 6px;
  font-weight: bold;
  z-index: 1;
  background: #438cee;
  color: #fff;
  border: none;

  &:focus {
    outline: 0 !important;
  }

  @media only screen and (max-width: 1200px) {
    font-size: 16px;
  }
  
  &:hover {
    background: transparent;
    color: #438cee;
    transition: all 300ms ease;
    cursor: pointer;
    span {
      background: linear-gradient(to right, #00c6ff, #0072ff);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      transition: all 300ms ease;
    }
  }
`;

export const Label = styled.p`
  margin-top: 8px;
  margin-bottom: 2px;
  font-size: 18px;
  color: #333;
`;

export const SubLabel = styled.p`
  margin: 4px 0px;
  padding: 0;
  font-size: 14px;
  color: #333;
`;

export const Textarea = styled.textarea`
  height: 60px;
  padding: 8px;
  border: 2px solid #e4e4e4;
  border-radius: 5px;
  color: #474747;
  resize: none;
  transition: all 300ms ease hover;
  width: 100%;
  font-size: 18px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  &:focus {
    border: 2px solid #438cee;
  }
`;

export const Waiver = styled.div`
height: 200px;
max-width: 100%;
overflow: scroll;
`